@import '../../styles/global';
@import '../../styles/variables';

.order-confirmation {
  margin: 40px 0;
  .order-content {
    > div {
      display: flex;
      flex-direction: column;
      gap: 24px;
      h4 {
        color: var(--specialty-dark);
      }
      h3,
      h4,
      p {
        margin-bottom: 0;
      }
    }
  }
  .order-summary {
    @include media-breakpoint-down(md) {
      flex-direction: column;
    }
    .inbv-info {
      width: 100%;
    }
    .nbv {
      text-align: left;
    }
    .trash-btn {
      display: none;
    }
    .order-tile-wrapper {
      flex-grow: 1;
    }
    .order-total-wrapper {
      width: 329px;
      @include media-breakpoint-down(md) {
        width: 100%;
      }
      .order-total {
        border-radius: var(--spacing-unit);
        .oc-header {
          svg {
            width: 26px;
            height: 24px;
          }
        }
        .hairline {
          border-top: 1px solid var(--secondary-dark);
        }
      }
    }
  }
  &.oc-loader {
    .order-tile-wrapper {
      width: 100%;
    }
  }
}
